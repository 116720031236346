

import React, { Component } from 'react';

export class AzureDocumentationLinks extends Component {

    static displayName = AzureDocumentationLinks.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <ul>
                    <li>
                        <a href="https://learn.microsoft.com/en-us/azure/cloud-shell/overview">Cloud Shell Overview</a>
                    </li>
                </ul>
            </div>
        )
    }
}