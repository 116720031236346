import React, { Component } from 'react';

export class AzurePowershellSwitchSubscription extends Component {

    static displayName = AzurePowershellSwitchSubscription.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1>Powershell - Switch Azure Subscription / Resource Group</h1>
                <div>
                    Cleanest way - get an set subscription as one-liner, pipe Get to Set:
                </div>
                <div>
                    Get-AzSubscription -SubscriptionName 'Concierge Subscription' | Set-AzContext
                </div>
                <div>
                    <div>Display current resource group:</div>
                    <div>
                        Get-AzSubscription
                    </div>
                </div>
                <div>
                    <div>Show available subscriptions:</div>
                    <div>
                        Get-AzSubscription
                    </div>
                </div>
                <div>
                    <div>Save subscription context to var, then use:</div>
                    <div>
                        $context = Get-AzSubscription -SubscriptionName 'Concierge Subscription'
                        Set-AzContext $context
                    </div>
                </div>
                <div>
                    <img src="https://i.imgur.com/FYXMv5b.png" alt="Switch Azure Subscription Powershell screenshot" />
                </div>
                <div>
                    {/*
                    <blockquote class="imgur-embed-pub" lang="en" data-id="a/qIKu7al" data-context="false" >
                        <a href="//imgur.com/a/qIKu7al"></a></blockquote><script async src="//s.imgur.com/min/embed.js" charset="utf-8"></script>
                    */} 
                </div>
            </div>
        );
    }
}