import React, { Component } from 'react';

export class AspNetCoreCheatSheet extends Component {
    static displayName = AspNetCoreCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>.Net Core Cheat Sheet</h3>
                <article>
                    <h4>
                        Azure App Insights custom properties:
                    </h4>
                    <h5>
                        Using ILogger.BeginScope()
                    </h5>
                    <div>
                        {/*{"*/}
                        {"using (this.logger.BeginScope(new List<KeyValuePair<string, object>>()"}
                        <br />{"{"}
                        <br />&nbsp;&nbsp;{"    new KeyValuePair < string, object > (nameof(myPrimitiveVarName), myPrimitiveVarName),"}
                        <br />&nbsp;&nbsp;{"    new KeyValuePair < string, object > (nameof(myObjectVarName), JsonConvert.SerializeObject(myObjectVarName))"}
                        <br />{"})) { ..... }"}
                        {/*{*/}
                        {/*    ...*/}
                        {/*}*/}
                        {/*"}*/}
                    </div>
                </article>
                <section>
                    <h4>Read raw request body:</h4>
                    <div>
                        Tried this:
                    </div>
                    <div>
                        var rawRequestBody = await new StreamReader(request.Body).ReadToEndAsync();
                    </div>
                    <div><a href="https://markb.uk/asp-net-core-read-raw-request-body-as-string.html">Source: asp-net-core-read-raw-request-body-as-string</a></div>
                    <div>
                        <b>Solution:</b> none of those solutions (EnableWhatever() middleware) worked.
                        <br /> Ended up accepting a dynamic object:
                        <br /> [FromBody] dynamic request
                        <br /> and then doing JsonConvert.Deserialize...
                    </div>
                </section>
                <article>
                    <h4>Updating Nuget packages</h4>
                    <div>
                        <h5>Package downgrade error:</h5>
                        <div>
                            Severity	Code	Description	Project	File	Line	Suppression State
                            Error	NU1605	<b>Detected package downgrade</b>
                        </div>
                        <h5>Solution: Update All</h5>
                        <div>
                            When I tried to update packages individually, I got "detected package downgrade" error
                            <img src="https://i.imgur.com/dIDCJX1.png" />
                        </div>
                        <div>
                            To get them all to update I clicked the Update All checkbox and updated them all at the same time
                            <img src="https://i.imgur.com/dlR84PA.png" />
                        </div>
                    </div>
                    <div>
                        Example:<br />
                        {"<div style={{ display: 'none'}}>"}
                    </div>
                </article>

            </div>
        );
    }
}
