import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class CssCheatSheet extends Component {
    static displayName = CssCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Css Cheat Sheet</h3>
                <article>
                    <h4>Materialize</h4>
                    <div><a href="https://materializecss.com/templates/starter-template/preview.html">Materialize start template</a>
                </div>
                </article>
                <article>
                    <title>&lt;article&gt; and &lt;title&gt; styles using html tag names:</title>
                    <div>
                        In your CSS file you the syntax is to <b>just use the html tag name</b>,
                        below example is:
                        <div><b>article &#123;....&#125;</b></div>
                    </div>
                    <div>
                        If you want the styles to apply to a tag that is contained in a specific tag then the syntax is:
                        <br /><b>tag-name-1 tag-name-2 &#123;....&#125;</b>
                        <div>
                            Example below is: <b>article title &#123;....&#125;</b>
                        </div>
                    </div>
                    <div>
                        To specify that <b>class only applies to a particular html tag</b> syntax is:
                        <div><b>[tag-name].[class-name]</b></div>
                        <div>
                            Example below is: <b>div.solution &#123;....&#125;</b>
                        </div>
                    </div>
                    <div>
                        <p>TODO: move this into a diff page called basics or something like one of these titles:</p>
                        <p>
                            &lt;section&gt; turned out to be the wrong html tag I wanted. Turns out "section"
                        means "section of something bigger that you want to inherit stuff from"
                    </p>
                    <p>
                        I was looking for &lt;article&gt;
                    </p>
                    <p>
                        Also, the &lt;title&gt; title tag already had a style of "display=none"
                        <br></br>bc there is a &lt;title&gt; element on the page, I think it's used for SEO,
                        is it usually in the &lt;head&gt; tag for SEO?
                        <br /> Well, I want to leave that invisibility but I want to keep using my title tag
                        bc that makes sense to me, it's the title of the thing. So:
                    </p>
                    <p>
                        <title>Nest tag names:</title>
                        <div>section title &#123;</div>
                        <note>(Side note: use & # 123 semicolon for left curly brace, 125 for right curly brace)</note>
                    </p>
                    <div>
                        I opemed up <b>custom.css</b> (sounds / sounded right) and added some styles:
                    </div>
                    <div>
                        Now I want to add some screenshots of this custom.css so...
                        <Link to='/imgur-upload-and-embed-image'>Imgur: Upload and Embed Image</Link>
                        <div>and here you go:</div>
                    </div>
                    <div>
                        Styles commented out, html on the right:
                        <img src="https://i.imgur.com/mdNpGxn.png" />
                    </div>
                    <div>
                        Styles not commented, html on the right:
                        <img src="https://i.imgur.com/n3jw1er.png" />
                    </div>
                    <p>
                        I formatted the file (Ctrl+K+D in Visual Studio but I think this auto-formatted at some point)
                        <div>
                            And I had added some other basic stuff, a big link, bold for a div with class="solution"
                        </div>
                        <img src="https://i.imgur.com/Zz1x9eP.png" />
                    </p>
            </div>
                </article >
            </div >
        );
    }
}