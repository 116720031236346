import React, { Component } from 'react';

export class CSharpCheatSheet extends Component {
    static displayName = CSharpCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>C# Cheat Sheet</h3>
                <section>
                    <h4>Read raw request body:</h4>
                    <div>
                        var rawRequestBody = await new StreamReader(request.Body).ReadToEndAsync();
                    </div>
                    <div><a href="https://markb.uk/asp-net-core-read-raw-request-body-as-string.html">Source: asp-net-core-read-raw-request-body-as-string</a></div>
                </section>
            </div>
        );
    }
}
