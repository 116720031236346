import React, { Component } from 'react';

export class PowershellCheatSheet extends Component {

    static displayName = PowershellCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1>Powershell Cheat Sheet</h1>
                <article>
                    <h4>Display Version</h4>
                    <div>
                        $PSVersionTable.PSVersion
                    </div>
                </article>
                <article>
                    <h4>Find in Files:</h4>
                    <div>
                        $path = "c:\repos\"
                        <br />$searchFor = "duder"
                        <br />Get-ChildItem -Path $path -Recurse | Select-String $searchFor -List | Select Path
                    </div>
                </article>
                <article>
                    <h4>Delete Bin and Obj Directories</h4>
                    <div>
                        $rootPath = "c:\replace-with-root-directory-path"<br />
                        {'@("bin", "obj") | %{Get-ChildItem -Recurse -Directory $rootPath -Filter $_ } | % { if (!$_.fullname.Contains("node_modules")) {Write-Host "Deleting " $_.fullname -ForegroundColor Red; Remove-Item $_.fullname -Force -Recurse } }'}
                    </div>
                </article>
                <article>
                    <h4>Module not found / Module not loaded</h4>
                    <div>
                        Example: Import-Module: The specified module 'AzSK' was not loaded because no valid module file was found in any module directory.
                    </div>
                    <div>
                        Solution: Install-Module
                    </div>
                    <div>
                        Install-Module -Name Az -Scope CurrentUser -Repository PSGallery -Force
                    </div>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/powershell/azure/install-az-ps?view=azps-9.3.0">powershell 7 install azure module</a>
                    </div>
                </article>
                <article>
                    <h4>Find Files (by text in file name)</h4>
                    <div>
                        Get-ChildItem -Recurse -Path "D:\your-root-dir-path" | Select-String "string-you-are-looking-for-in-name" -List | Select Path
                    </div>
                    <div>
                        gci -recurse -filter "*.nuspec" -File -Path "D:\your-root-dir-path" | Select Path
                    </div>
                    <div>
                        gci -recurse -filter "*AssemblyInfo*" -File -Path "D:\your-root-dir-path"
                    </div>
                </article>
                <article>
                    <h4>Update PATH</h4>
                    <div>
                        Write-Output $env:Path
                    </div>
                    <div>
                        $env:Path += "; C:\Program Files (x86)\NuGet"
                    </div>
                </article>
                <article>
                    <h4>VSCode Powershell ISE mode</h4>
                    <div>
                        Write-Output $env:Path
                    </div>
                    <div>
                        The easiest way to replicate the ISE experience in Visual Studio Code is by turning on "ISE Mode". To do this, open the command palette (F1 OR Ctrl+Shift+P OR Cmd+Shift+P on macOS) and type in "ISE Mode". Select "PowerShell: Enable ISE Mode" from the list.
                    </div>
                    <h4>
                        Install Powershell Extension in VSCode
                    </h4>
                    <div>
                        <img src="https://imgur.com/jsldrSP" alt="install vscode powershell extension" />
                    </div>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/powershell/scripting/install/installing-powershell-on-windows?view=powershell-7.3">
                            Install Powershell on Windows
                        </a>
                    </div>
                </article>
            </div>
        )
    }
}
