import React, { Component } from 'react';

export class SetupLocalSqlServerExpress extends Component {

    static displayName = SetupLocalSqlServerExpress.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <a href="https://learn.microsoft.com/en-us/sql/database-engine/configure-windows/sql-server-express-localdb?view=sql-server-ver16">Local Sql Express</a>
                <a href="https://learn.microsoft.com/en-us/sql/ssms/download-sql-server-management-studio-ssms?redirectedfrom=MSDN&view=sql-server-ver16">First download link</a>
                <a href="https://learn.microsoft.com/en-us/sql/ssms/download-sql-server-management-studio-ssms-19?view=sql-server-ver16">Download SSMS 19 Preview</a>
                <a href="https://stackoverflow.com/questions/10479763/how-to-get-the-connection-string-from-a-database">How to get con str</a>
                <a href="https://learn.microsoft.com/en-us/visualstudio/data-tools/add-new-connections?view=vs-2022">Setup New Connection for VS2022</a>

                Roundhouse not working, not connecting
                Tried SSMS_Security_SqlServerAndWindowsAuthentication.png

                <div>IMAGE CON STR VS2022</div>
                <a href="https://learn.microsoft.com/en-us/sql/relational-databases/sql-server-configuration-manager?view=sql-server-ver16">How to open SQL Server Config Manager</a>
                C:\Windows\SysWOW64
                <a href="https://learn.microsoft.com/en-us/answers/questions/190516/cannot-connect-to-sqlexpress.html">Troubleshoot connection</a>
                IMGs of Config Manager

                Re-install roundhouse
                IMG cinst_non_admin
                started Powershell 7 as admin
                powershell7_cinst_roundhouse.png
                https://databasefaqs.com/sql-server-agent-wont-start/
                change_SqlServerAgent_BuiltInAccount_to_LocalSystem.png
                change_SqlServerAgent_LogOnAs_to_ThisAccount.png

                changed_startup_types_to_auto.png

                restart
            </div>
        )
    }
}

