import React, { Component } from 'react';

export class ReactAddLink extends Component {
    static displayName = ReactAddLink.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <section>
                    <h3>How to add a link / anchor in React</h3>
                    <div>
                        Add this import:
                    </div>
                    <div>
                        {"import {Link} from 'react-router-dom';"}
                    </div>
                    <div>
                        Then add this to your page / JSX:
                    </div>
                    <div className="bold">
                        {"import {Link} from 'react-router-dom';"}
                    </div>
                    <div className="bold">
                        {"<Link to='/react-add-link'>Add a link in React</Link>"}
                    </div>
                    <div>
                        Don't forget to add the path to the AppRoutes file:
                    </div>
                    <div>
                        <img src="https://i.imgur.com/QEJHr6O.png" alt="React add link AppRoutes" />
                    </div>
                </section>
                <article>
                    <title>Error: 'Whatever' is not defined</title>
                    <div>In my case: <div>Line 50:30:  'Link' is not defined  react/jsx-no-undef</div></div>
                    <div>
                        <img src="https://i.imgur.com/nzNAKEy.png" />
                    </div>
                    <div class="solution">Solution:</div>
                    <p>
                        Forgot to import it dude!
                        <br />Looks like it's in react-router-dom
                    </p>
                    <p>
                        Added this to the top of the page:
                        <div class="solution">
                            import &#123;Link&#125; from 'react-router-dom';
                        </div>
                    </p>

                </article>
            </div>
        );
    }
}
