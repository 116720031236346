import React, { Component } from 'react';

import gitNotRecognizedImage from './Images/GitIsNotRecognizedAsANameOfACmdlet.png';

export class GitNotRecognizedPage extends Component {
    static displayName = GitNotRecognizedPage.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <article>
                    <h4>Error: git: The term 'git' is not recognized as a name of a cmdlet, function, script file, or executable program.</h4>
                    <div>
                        You can set via command prompt but it's not as straightforward as Powershell,
                        instead go - Start menu => Environment Variables => Advanced => Environment variables button
                    </div>
                </article>
                <article>
                    <h4>Command prompt note:</h4>
                    <div>
                        You can set via command prompt but it's not as straightforward as Powershell,
                        instead go - Start menu => Environment Variables => Advanced => Environment variables button
                    </div>
                </article>
                <div>
                    Solution (provided git installed (in that directory)):
                </div>
                <div>
                    Powershell command:
                </div>
                <div>
                    $env:Path += ';C:\Program Files\Git\bin\;C:\Program Files\Git\cmd\'
                    <div>
                        Note: check the path, on a computer where I installed GitHub desktop the path was:
                        <div>
                            c:\ProgramData\[user-name]\GitHubDesktop\app-[version-num]\resources\app\git\cmd
                        </div>
                    </div>
                </div>
                
                <img src={gitNotRecognizedImage} />
                <div>
                    Re-runnable script:
                </div>
                <div>
                    if ($env:Path.Contains("C:\Program Files\Git\bin\")) &#123;
                        Write - Output "git bin folder in path"
                    &#125;
                    else &#123;
                        Write - Output "git bin folder not in path - adding";
                    $env:Path += ";C:\Program Files\Git\bin\;"
                    &#125;
                    if ($env:Path.Contains("C:\Program Files\Git\cmd\")) &#123;
                        Write - Output "git cmd folder in path"
                    &#125;
                    else &#123;
                        Write - Output "git cmd folder not in path - adding";
                    $env:Path += ";C:\Program Files\Git\cmd\;"
                    &#125;

                </div>
                <div>
                    if ($env:Path.Contains("C:\Program Files\Git\bin\")) &#123;
                    <br />
                    <br />Write - Output "git bin folder in path"
                    <br />&#125;
                    <br />else &#123;
                    <br />Write - Output "git bin folder not in path - adding";
                    <br />$env:Path += ";C:\Program Files\Git\bin\;"
                    <br />&#125;
                    <br />if ($env:Path.Contains("C:\Program Files\Git\cmd\")) &#123;
                    <br />Write - Output "git cmd folder in path"
                    <br />&#125;
                    <br />else &#123;
                    <br />Write - Output "git cmd folder not in path - adding";
                    <br />$env:Path += ";C:\Program Files\Git\cmd\;"
                    <br />&#125;
                </div>
                <div>
                    <b>Add to path w/ variable for path:</b>
                    <div>
                        <br/>$pathToAddToPath = "C:\Program Files\Git\bin\"
                        <br />if ($env:Path.Contains($pathToAddToPath)) &#123;
                        <br />Write - Output "$pathToAddToPath already in path"
                        <br />&#125;
                        <br />else &#123;
                        <br />Write - Output "$pathToAddToPath not in path - adding it";
                        <br />$env:Path += ";$pathToAddToPath"
                        <br />&#125;
                    </div>
                </div>
                <div>
                    <b>GitHub Desktop path:</b>
                    <div>
                        c:\Users\[user-name]\AppData\Local\GitHubDesktop\app-[version]\resources\app\git\cmd
                    </div>
                    <div>
                        c:\Users\[user-name]\AppData\Local\GitHubDesktop\app-[version]\resources\app\git\cmd
                    </div>
                </div>
            </div>
        );
    }
}