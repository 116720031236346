import React, { Component } from 'react';

import addNewFoldersAndFilesImg from './CreateNewReactPageImages/CreateNewPage_1_AddFoldersAndJsFile.png';
import verticalTabGroupImg from './CreateNewReactPageImages/CreateNewPage_2_VerticalTabGroup.png';
import minCodeImg from './CreateNewReactPageImages/CreateNewPage_3_MinPageCode.png';
import debugButtonImg from './CreateNewReactPageImages/CreateNewPage_4_DebugButton.png';
import fetchDataUrl from './CreateNewReactPageImages/CreateNewPage_5_FetchDataUrl.png';
import serarchSolutionImg from './CreateNewReactPageImages/CreateNewPage_6_SearchSolution.png';
import searchSolutionResults from './CreateNewReactPageImages/CreateNewPage_6_SearchSolutionResults.png';
import addNewAppRouteImg from './CreateNewReactPageImages/CreateNewPage_7_AddNewAppRoute.png';
import helloWorldImg from './CreateNewReactPageImages/CreateNewPage_8_HelloWorld.png';
import addingTextImg from './CreateNewReactPageImages/CreateNewPage_10_AddingText.png';
import addImageError from './CreateNewReactPageImages/CreateNewPage_11_AddImageError.png';
import showImagesImg from './CreateNewReactPageImages/CreateNewPage_12_ShowImages.png';
import dupeVarNameImg from './CreateNewReactPageImages/CreateNewPage_13_ShowDupeImageNameError.png';
import dupeVarNameImg2 from './CreateNewReactPageImages/CreateNewPage_13_2_ShowDupeImageNameError.png';
import correctCommentImg from './CreateNewReactPageImages/CreateNewPage_14_ShowCorrectCommentFix.png';
import curlyBraceErrorImg from './CreateNewReactPageImages/CreateNewPage_15_CurlyBraceError.png';
import curlyBraceEscapedImg from './CreateNewReactPageImages/CreateNewPage_15_CurlyBracesEscaped.png';

export class CreateNewReactPage extends Component {
    static displayName = CreateNewReactPage.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1>Creating a new React page</h1>
                <h4>
                    With Visual Studio 2022
                </h4>
                <p>
                    Note to self: come back here and add a link to an article that explains how to create the web project.
                </p>
                <p>
                    I added some new folders and javascript files for my first articles.
                </p>
                <img src={addNewFoldersAndFilesImg} />
                <div>
                    Sidenote: notice the comment above, it doesn't use &lt;!-- my comment --{'>'} syntax,
                    <a href="https://wesbos.com/react-jsx-comments">here is an article about JSX comments</a>
                </div>
                <p>
                    Open FetchData.js and the right click the tab =&gt; New Vertical Document Group to look at
                    the code for the new page alongside the existing page
                </p>
                <img src={verticalTabGroupImg} />
                <p>
                    Copy and paste code from the Fetch Data page and scrap all the stuff that is not necessary to just display "hello world"
                </p>
                <img src={minCodeImg} />
                /* TODO: figure out how to add a block of code */
                <p>
                    Oops! Looks like my TODO comment didn't render as expected. We'll look at that in one sec.
                </p>
                <p>
                    Click this debug button at the top of Visual Studio to run the web app:
                </p>
                <img src={debugButtonImg} />
                <p>
                    Click on the "Fetch Data" link in the page header and note the URL ends with "fetch-data"
                </p>
                <img src={fetchDataUrl} />
                <p>Where did that "fetch-data" text in the URL come from?
                </p>
                <p>Press Ctrl+Alt+F and search your solution for "fetch-data"
                </p>
                <img src={serarchSolutionImg} />
                <p>At the bottom of VS you'll see some results, click on match in the AppRoutes.js folder.
                </p>
                <img src={searchSolutionResults} />

                <p>It looks like this AppRoutes.js file maps URL paths to pages / react components.</p>
                <p>Let's duplicate the stuff set up for the FetchData component but change it to "CreateNewReactPage" and let's
                    make the route "create-new-react-page". <br />Note: "CreateNewReactPage" is the name of the new component that we're exporting
                    in that new file. <br /> Also note: we have to add the "import" line at the top of the page too.</p>
                <img src={addNewAppRouteImg} />
                <p>
                    Now click the debug button at the top of Visual Studio again (if the app is no longer running). Click on the Fetch Data
                    link, but then in the URL change "fetch-data" to "create-new-react-page"
                </p>
                <img src={helloWorldImg} />
                <p>
                    Hey... how about that? We've created a new react page! Totally amazing!
                </p>
                <p>
                    Now let’s add some text. I'm going to use the &lt;p&gt;paragraph&lt;\p&gt; HTML tags.
                </p>
                <img src={addingTextImg} />
                <p>
                    Ok... my comments didn't come out as expected, I'll address that in (another) second.
                </p>
                <p>First let's add an image. <br /> Here's a link to a page that shows
                    <a href="https://create-react-app.dev/docs/adding-images-fonts-and-files/"> how to add images</a> to a React page.
                </p>
                <p>
                    Oops! I got this error:
                </p>
                <img src={addImageError} />
                <p>
                    I messed up the path in my import statement. I took a screen clip of that and we'll see here if we can get both images.
                </p>
                <img src={showImagesImg} />
                <p>
                    Ok, let's go ahead and add the rest of these images and call it a day.
                </p>
                <p>
                    Oops! When I went to add the next image I forgot to change the name of the image variable in the import statement.
                </p>
                <p>
                    You can see that the page and the console are pretty clear about what I messed up:
                </p>
                <img src={dupeVarNameImg} />
                <img src={dupeVarNameImg2} />
                <p>Let's revisit that comment error - what did I goof?</p>
                <p>The comments need to be wrapped in curly braces. I think the curly braces essentially say "hey, this is javascript, not HTML,
                    evaluate this javascript"</p>
                <img src={correctCommentImg} />
                <p>
                    What if I want to display a curly brace in my HTML? {'{'} Does this work? {'}'}
                </p>
                <img src={curlyBraceErrorImg} />
                <p>No, that did not work. Not sure if this is the cleanest way... but here's one way to do it.</p>
                <img src={curlyBraceEscapedImg} />
                <p>
                    Ok so... I think that's it for this article. I wouldn't say this is my greatest piece of work, but I hope that maybe 
                    I'll help someone out by going through this process fairly meticulously, with the screenshots and whatnot.
                </p>
                <p>
                    And you gotta start somewhere. Article numero uno complete. On to more interesting stuff!
                </p>
                <p>
                    P.S. For curly braces you can also use:
                    <br /> <span>Left curly brace:</span><span>&</span><span>#123;</span>
                    <br /> <span>Right curly brace:</span><span>&</span><span>#123;</span>
                    <a href="https://stackoverflow.com/questions/46614716/render-curly-braces-as-plain-text-in-react-jsx">JSX escape curly braces on Stackoverflow</a>"
                </p>
            </div>
        );
    }
} 