import React, { Component } from 'react';

export class DotNetReactBasics extends Component {
    static displayName = DotNetReactBasics.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>.Net Core React Basics</h3>
                <article>
                    <h4>Create New React Project / Deploy to Azure Doc</h4>
                    <div>
                        <a href="https://1drv.ms/w/s!AkvhZHeI6vpl2iX1DvmQBK3CPr-6?e=DnsJ13">Create New React Project / Deploy to Azure Doc</a>
                    </div>
                </article>
                <article>
                    <h4>New Component</h4>
                    <div>
                        <a href='https://beta.reactjs.org/learn/your-first-component'>New component documentation</a>
                    </div>
                </article>
                <article>
                    <h4>JSX Inline Styles - use JSON object (not string)</h4>
                    <div>
                        Example:<br />
                        {"<div style={{ display: 'none'}}>"}
                    </div>
                </article>
                <article>
                    <h4><a href="https://www.npmjs.com/package/react-datepicker?activeTab=readme">DatePicker</a></h4>
                    <div>
                        <b>npm:</b><br />
                        npm install react-datepicker --save
                    </div>
                    <div>
                        <b>Imports:</b><br />
                        import DatePicker from "react-datepicker";<br />
                        import "react-datepicker/dist/react-datepicker.css";
                    </div>
                    <div>
                        <b>JSX:</b>
                        <br />
                        {"<DatePicker selected={this.state.endDate} onChange={this.endDateChange} />"}
                    </div>
                    <div>
                        <b>Gotcha / time zones</b>
                        <div>
                            My scenario, I only cared about the date (not the time) DatePicker has some timezone logic in it, it's a known issue / feature.
                            <br />The dates posted to my endpoint didn't match the dates I saw onscreen in the textbox, so here's what I did.
                        </div>
                        <div>
                            <div>
                                1) Added date str props to state:
                            </div>
                            <div>
                                {"this.state = {"}
                                <br />...
                                <br />endDate: today,
                                <br />endDateStr: today.toLocaleDateString("en-US"),
                                <br />...
                                <br />{"}"}
                            </div>
                            <div>2) Set the string property in onChange:</div>
                            <div>
                                {"endDateChange(date) {"}
                                <br />{"this.setState({endDate: date, endDateStr: date.toLocaleDateString(\"en-US\") })"}
                                <br />{"}"}
                            </div>
                            <div>
                                3) Posted state.endDateStr instead of state.endDate
                            </div>
                        </div>
                    </div>
                </article>
                npm install react-datepicker --save
                <article>
                    <h4>Visual Studio - new project doesn't run when click debug</h4>
                    <div>
                        <ul>
                            <li>Open solution folder</li>
                            <li>new terminal (ctrl+shift+`)</li>
                            <li>cd to project and then probably ClientApp (wherever packages.json is)</li>
                            <li>command: npm run build</li>
                            <li>
                                <div>
                                    Resolve Errors
                                </div>
                                <ul>
                                    <li>'react-scripts is not recognized' error then use command:
                                        <div>
                                            npm install react-scripts --save
                                        </div>
                                    </li>
                                    <li>
                                        Some package is messed up
                                        <br />I had:
                                        <br />\node_modules\eslint-webpack-plugin\node_modules\jest-worker\build\index.js:110
                                        <br />_ending;
                                        <br />^
                                        <br />SyntaxError: Unexpected token ;
                                        <br />at Module._compile (internal/modules/cjs/loader.js: 721: 23))....
                                        <div class="solution">
                                            Solution: <a href="https://stackoverflow.com/questions/12866494/how-do-you-reinstall-an-apps-dependencies-using-npm">
                                                delete the node modules folder and run "npm install"</a>
                                            <br /> This didn't work. I fixed a similar issue (I think...) by deleting node_modules and overwriting
                                            <br /> packages.json and package-lock.json with the files from a new / freshly created project
                                            <br /> un-ideal... I need to learn more about resolving npm dependency conflicts
                                            <br /> dependency conflicts... npm or Nuget - the frickin worst. Seems strange that this is still a problem
                                            <br /> a good 20 years after "DLL hell"  These package managers were supposed to solve these problems right?
                                            <br /> TODO: research and create a seperate page specifically for resolving npm conflicts
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                Error: error:0308010C:digital envelope routines::unsupported
                                            </div>
                                            <div>
                                                <img src="https://i.imgur.com/SOOVEaF.png" />
                                            </div>
                                            <div>
                                                Solution: <a href="https://www.freecodecamp.org/news/error-error-0308010c-digital-envelope-routines-unsupported-node-error-solved/">followed this</a>
                                                <div>
                                                    And executed these npm command lines from command prompt to upgrade react-scripts:
                                                    <div>
                                                        npm uninstall react-scripts
                                                    </div>
                                                    <div>
                                                        npm install react-scripts
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Now go back to VS and click debug button. Or - run from VSCode terminal with command: npm run start
                            </li>
                        </ul>
                    </div>
                </article>
                <article>
                    <h4>Routing - setupProxy.js</h4>
                    <div>
                        After creating the project, I added a new controller and was <span class="nope">unable to hit endpoints in the controller.</span> I then duplicated
                        the WeatherForecast controller - which I was able to hit - and renamed it "WeatherController" and tried to drop the "forecast"
                        from my postman request. I was still unable to hit the default HttpGet endpoint.
                    </div>
                    <div>
                        There is a <b>setupProxy.js</b> file in the project that I am thus far unfamiliar with. I finally found it when I searched my
                        solution for "WeatherForecast" but unchecked the "Match case" checkbox.
                    </div>
                    <div>
                        After adding my route(s) to <b class="nope">setupProxy.js</b> I was able to hit my endpoints.
                    </div>
                    <div>
                        <img src="https://i.imgur.com/gzKtLSb.png" />
                    </div>
                    <div>
                        So... this was really mega frustrating. <br /> I don't understand why anything in setupProxy.js
                        is going to have an effect on routing in my application - when I am not using the front-end at all.
                        <br /> When the app runs, the front-end code somehow tells my backend code which endpoints and routes
                        are allowed? This seems... horrible. Yes... the front-end is coupled with backend in this project type,
                        I did not create a front-end react site with a separate backend. But... this setupProxy.js bs seems to me
                        to make them now qualify as more <span class="nope">MEGA TIGHTLY COUPLED</span>
                    </div>
                    <div>
                        Ok... here's the deal. My backend was running on port 7133, I see this in the console window when I start debugging:
                        <div style={{ margin: "0 2em" }}>
                            info: Microsoft.Hosting.Lifetime[14] <br />
                            Now listening on: https://localhost:7133
                        </div>
                        <div>
                            When I changed my postman requests to use that base url / 7133 port, all routing worked as expected, without having to add to setupProxy.js
                        </div>
                        <div>
                            Ok, that makes a little more sense, but... I'm still POed. And am I going to have to add routes to this setupProxy.js file
                            everytime I want to hit a new controller / endpoint?
                        </div>
                    </div>
                    <div>
                        <h4>
                            setupProxy.js Route Error:
                        </h4>
                        <div className="bold">
                            .... at next (D:\Projects\Stonksworth\Stonksworth.Website\Stonksworth.Website\ClientApp\node_modules\express\lib\router\route.js:140:7)
                        </div>
                        <div>
                            <img src="https://i.imgur.com/itTuobh.png" />
                        </div>
                        <div>
                            Now... what bothers me is not so much that this causes a problem / error, but what
                            I find really annoying is that you click "Debug" / F5 and... the thing runs forever.
                            <br /> The back-end starts, and then... it keeps trying to start the front-end. Forever.
                            <br /> I went to delete my node_modules, it was some huge number of megabytes. I haven't  added any projects... just chart-js. Dang...
                            <br /> I am consider a Blazer (Blazor?) app.
                            <br /> Anyway... don't put a wildcard in the setupProxy.js routes. Don't mess up the routes in that file. Or you debug and Visual Studio will keep trying to start your dev server forever.
                            <br /> P.S. I found the error by running <b>npm run start</b> in my project directory in Visual Studio Code.
                        </div>
                        <div>
                            <h4>
                                Another setupProxy.js route error:
                            </h4>
                            <div>
                                <img src="https://i.imgur.com/KOOUfsi.png" />
                            </div>
                        </div>
                    </div>
                </article>
                <article>
                    <h4>ChartJS</h4>
                    <div>
                        <a href="https://react-chartjs-2.js.org/">npm install --save chart.js react-chartjs-2</a>
                    </div>
                    <div>
                        <a href="https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale">Reminder: Gotta import auto from chartjs to use ChartJS2!</a>
                        <img src="https://i.imgur.com/cVYn6gB.png" />
                    </div>
                </article>
            </div>
        );
    }
}
