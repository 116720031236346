import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MSLearnAzureActiveDirectory extends Component {
    static displayName = MSLearnAzureActiveDirectory.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <a href="https://learn.microsoft.com/en-us/training/modules/explore-azure-active-directory/">Explore Azure Active Directory</a>
                <section>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/training/modules/explore-azure-active-direct1ory/2-examine-azure-active-directory">
                            Unit 2: Examine Azure Active Directory
                        </a>
                        <div>
                            "At any given time, an Azure subscription must be associated with one, and only one, Azure AD tenant."
                            <br /> However: "you can associate the same Azure AD tenant with multiple Azure subscriptions."
                            <br /> "This allows you to use the same users, groups, and applications to manage resources across multiple Azure subscriptions."
                            <br /> The tenant is "security boundary" for users / groups / apps (across mult subscriptions)
                            <br /><br />
                            This is really confusing:
                            <div>
                                "Objects of the Application and servicePrincipal classes represent applications in Azure AD. An object in the Application class contains an application definition and an object in the servicePrincipal class constitutes its instance in the current Azure AD tenant. Separating these two sets of characteristics allows you to define an application in one tenant and use it across multiple tenants by creating a service principal object for this application in each tenant. Azure AD creates the service principal object when you register the corresponding application in that Azure AD tenant."
                            </div>
                            <div>
                                What is that saying??? something like: apps have "servicePrincipal" objects in each tenant
                                where it's going to be used
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/training/modules/explore-azure-active-directory/3-compare-azure-active-directory-domain-services">
                            Unit 3: Compare Azure AD and Active Directory Domain Services
                        </a>
                        <div>
                            What is a "directory service" anyway?
                        </div>
                    </div>
                    <div>
                        <div>
                            Unit 4:
                        </div>
                        <div>
                            Weak: "process of implementing Azure AD support for custom applications is rather complex and beyond the scope of this course"
                        </div>
                        <div>
                            <a href="https://learn.microsoft.com/en-us/azure/app-service/configure-authentication-provider-aad">
                                Configure your App Service app to use Azure Active Directory login.
                            </a>
                        </div>
                    </div>
                    <div>
                        <div>
                            <a href="https://learn.microsoft.com/en-us/training/modules/explore-azure-active-directory/5-compare-azure-active-directory-premium-p1-p2-plans">
                                Compare Azure AD Premium P1 and P2 plans
                            </a>
                        </div>
                        <div>
                            <div>P2 has PIM:</div>
                            <div>
                                "Azure AD Privileged Identity Management. This functionality lets you configure additional security
                                levels for privileged users such as administrators. With Privileged Identity Management, you define
                                permanent and temporary administrators. You also define a policy workflow that activates
                                whenever someone wants to use administrative privileges to perform some task."
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>Summary links:</div>
                        <div>
                            <a href="https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-whatis">
                                What is Azure Active Directory?
                            </a>
                        </div>
                    </div>
                </section>

                <section>
                    <h4>
                    </h4>
                    <div>
                        <div>
                            <a href="https://myapps.microsoft.com/">https://myapps.microsoft.com/</a>
                            <div>
                                Shows you the apps you have access to
                            </div>

                        </div>

                    </div>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/training/modules/manage-identities-azure-active-directory/2-examine-rbac-user-roles-azure-active-directory">
                            2-examine-rbac-user-roles-azure-active-directory</a>
                        <div>
                            <img src="https://imgur.com/z52RQot" />
                            <div>
                                This above paragraph seems so complicated to me. WTF?
                            </div>
                        </div>
                        <div>
                            The description of
                            <a href="https://learn.microsoft.com/en-us/mem/intune/fundamentals/what-is-intune">Microsoft Intune</a>
                            sounds like it is intended for exactly the same thing as Azure Active Directory:
                            <br /><b>Microsoft Intune securely manages identities, manages apps, and manages devices</b>
                            <br />But through AAD you can assign the role of Intune Admin? wtf?
                        </div>
                    </div>
                </section>

                <div>
                    <a href="https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-app-roles-in-azure-ad-apps">
                        howto-add-app-roles-in-azure-ad-apps
                    </a>
                </div>

            </div>
        )
    }
}