import React, { Component } from 'react';

export class AzureSwitchDirectory extends Component {
    //static displayName = class AzureSwitchDirectory.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div>
                    This is how you switch to the Microsoft Learn Sandbox directory
                </div>
                <div>
                    Go to <a href="https://portal.azure.com">https://portal.azure.com</a>
                </div>
                <div>
                    Click the top right corner and then click the "Switch" button
                    for the Microsoft Learn Sandbox directory
                </div>
                <div>
                    <img src="https://i.imgur.com/chIp5NN.png" alt="Azure Switch Directory" />
                </div>
            </div>
        );
    }
} 