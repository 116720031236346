import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class PostmanVariables extends Component {
    static displayName = PostmanVariables.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Postman Variables</h3>

                <article>
                    <h4>Postman Environment Variables</h4>
                    <div>
                        <Link to="/postman-cheat-sheet">back to Postman Cheat Sheet</Link>
                    </div>
                    <div>
                        <a href="https://learning.postman.com/docs/sending-requests/variables/">Postman Documentation: sending request variables</a>
                    </div>
                    <div>
                        <img src="https://i.imgur.com/s1huMKt.png" />
                    </div>
                </article>
            </div>
        );
    }
}
