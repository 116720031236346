import React, { Component } from 'react';

export class MSLearnBicep extends Component {
    static displayName = MSLearnBicep.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <a href="https://learn.microsoft.com/en-us/azure/azure-resource-manager/bicep/learn-bicep">TODO: MSLearn Bicep link</a>
                <br /><br />
                <h4>Bicep Parameters - Powershell that calls bicep file:</h4>
                <div>
                    <br />$context = Get-AzSubscription -SubscriptionName 'Concierge Subscription'
                    <br />Set-AzContext $context

                    <br />Get-AzResourceGroup

                    <br /><br />Set-AzDefault -ResourceGroupName learn-5dff3504-2547-4139-9e6e-22cbc16240e8

                    <br /><br />Set-Location .\templates\ParametersExercise

                    <br /><br />New-AzResourceGroupDeployment `
                    <br />-TemplateFile paramsExMain.bicep `
                    <br />-TemplateParameterFile paramsEx.parameters.dev.json

                    <br /><br />###
                </div>
                <h4>
                    Bicep Parameters - Bicep File:
                </h4>
                <div>
                    @description('the environment')
                    <br />
                    @allowed(['dev', 'test', 'prod'])
                    <br />
                    param environmentName string = 'dev'
                    <br />

                    <br />@description('the sol name')
                    <br />@minLength(5)
                    <br />@maxLength(30)
                    <br />param solutionName string = {"'toyhr${uniqueString(resourceGroup().id)}"}'

                    <br />@description('the num of app plan instances')
                    <br />@minValue(1)
                    <br />@maxValue(3)
                    <br />param appServicePlanInstanceCount int = 1

                    <br /><br />@description('name and tier of the app service plan SKU')
                    <br />param appServicePlanSku object

                    <br /><br />param location string = 'westus3'

                    <br />@secure()
                    <br />@description('The administrator login username for the SQL server.')
                    <br />param sqlServerAdministratorLogin string

                    <br /><br />@secure()
                    <br />@description('The administrator login password for the SQL server.')
                    <br />param sqlServerAdministratorPassword string

                    <br /><br />@description('The name and tier of the SQL database SKU.')
                    <br />param sqlDatabaseSku object


                </div>
                <h4>
                    Parameters JSON:
                </h4>
                <div>
                    {"{"}
                    <br />"$schema"{":"} "https://schema.management.azure.com/schemas/2019-04-01/deploymentParameters.json#",
                    <br />"contentVersion"{":"} "1.0.0.0",
                    <br />"parameters"{":"} {"{"}
                    <br />"appServicePlanSku"{":"} {"{"}
                    <br />"value"{":"} {"{"}
                    <br />"name"{":"} "F1",
                    <br />"tier"{":"} "Free"
                    <br />{"}"}
                    <br />{"}"},
                    <br />"sqlDatabaseSku": {"{"}
                    <br />"value": {"{"}
                    <br />"name": "Standard",
                    <br />"tier": "Standard"
                    <br />{"}"}
                    <br />{"}"}
                    <br />{"}"}
                    <br />{"}"}
                </div>
            </div>
        )
    }
}