import React, { Component } from 'react';

export class CreateNugetPackage extends Component {

    static displayName = CreateNugetPackage.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <a href="https://learn.microsoft.com/en-us/nuget/create-packages/creating-a-package">MSFT - Create a package using the nuget.exe CLI</a>
                <a href="https://learn.microsoft.com/en-us/nuget/quickstart/create-and-publish-a-package-using-visual-studio-net-framework">Quickstart: Create and publish a package using Visual Studio (.NET Framework, Windows)</a>
                <div>
                    ". Add the nuget.exe file to a suitable folder, and add that folder to your PATH environment variable."
                </div>
                <div>
                    $env:Path += "; C:\Program Files (x86)\NuGet"
                </div>
                <div>
                    Error:
                    <div>
                        Error NU5133: NuGet.exe file on path C:\Program Files (x86)\NuGet\nuget.exe needs to be unblocked after downloading.
                    </div>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/nuget/reference/errors-and-warnings/nu5133">Nuget Warning NU5133</a>
                    </div>
                    <div>
                        This worked: 
                        Unblock-File -Path "C:\Program Files (x86)\NuGet\nuget.exe"
                    </div>
                    <div>
                        Local package store article: https://burcakbas.medium.com/nuget-101-creating-and-consuming-a-nuget-package-8fcf75db2e35
                    </div>
                    <div>
                        What if I want to skip setting up the server? 
                        How do I publish a nupkg file without setting that up?
                    </div>
                    <div>
                        How the F did I do this in the first place? Did I just copy DLL?
                    </div>
                    <div>
                        Source param can be a folder?
                        https://spin.atomicobject.com/2021/01/05/local-nuget-package/
                    </div>
                    <div>
                        PUSH TO LOCAL FOLDER IMG

                    </div>
                    <div>
                        Unloaded projects, restarted VS2022, finally see v1.1.0 package
                        FINALLY IMG
                    </div>
                </div>
            </div>
            
        )
    }
}
