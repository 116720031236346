import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <section>
                    <h2>Powershell</h2>
                    <div>
                        <Link to='/powershell-cheat-sheet'>Powershell Cheat Sheet</Link>
                    </div>
                </section>

                <section>
                    <h2>Blazor</h2>
                    <div>
                        <a href="https://markscodecom.wordpress.com/2024/04/26/blazor-how-to-hot-reload/">App Service: create new publish profile and publish</a>
                    </div>
                </section>

                <section>
                    <h2>React</h2>
                    <div>
                        <Link to='/react-cheat-sheet'>React Cheat Sheet</Link>
                    </div>
                    <div>
                        <Link to='/dot-net-react-basics'>Dot Net React Basics / Starting new project</Link>
                    </div>
                    <div>
                        <Link to='/react-add-link'>Add a link in React</Link>
                    </div>
                    <div>
                        <Link to='/react-warnings'>React Warnings</Link>
                    </div>
                </section>

                <article>
                    <title>Azure</title>
                    <div>
                        <Link to='/azure-documentation-links'>Azure Documentation Links</Link>
                    </div>
                    <div>
                        <Link to='/azure-service-bus'>Azure Service Bus Basics</Link>
                    </div>
                    <div>
                        <Link to='/azure-switch-directory'>Switch Azure Directory</Link>
                    </div>
                    <div>
                        <a href="https://markscodecom.wordpress.com/2023/07/12/azure-app-service-new-publish-profile-and-publish/">App Service: create new publish profile and publish</a>
                    </div>
                    <h4>Azure Powershell</h4>
                    <div>
                        <Link to='/azure-powershell-switch-subscription'>Az Pow: Switch Subscription</Link>
                    </div>
                </article>

                <article>
                    <title>Python</title>
                    <div>
                        <a href="https://markscodecom.wordpress.com/2023/07/11/install-specific-python-package-version-with-pip-update-pip/">Install specific Python package version with PIP + Update PIP</a>
                    </div>
                </article>
                

                <section>
                    <h2>Sql</h2>
                    <div>
                        <Link to='/sql-cheat-sheet'>Sql Cheat Sheet</Link>
                    </div>
                    <div>
                        <Link to='/setup-local-sql-express'>Setup Sql Express (locally)</Link>
                    </div>
                </section>

                <section>
                    <h2>Git</h2>
                    <div>
                        <Link to='/git-cheat-sheet'>Git Cheat Sheet</Link>
                    </div>
                    <div>
                        <Link to='/git-not-recognized'>Git Not Recognized Error / Add Git to Environment Path variable</Link>
                    </div>
                </section>

                

                <section>
                    <h2>Nuget</h2>
                    <div>
                        <Link to='/create-nuget-package'>Create Nuget Package</Link>
                    </div>
                </section>

                <section>
                    <h2>MSLearn</h2>
                    <div>
                        <Link to="/mslearn-azureappservice">MSLearn App Service</Link>
                    </div>
                    <div>
                        <Link to='/mslearn-bicep'>MSLean - Bicep</Link>
                    </div>
                    <div>
                        <Link to='/mslearn-azure-active-directory'>MSLearn - Azure Active Directory</Link>
                    </div>
                </section>

                <section>
                    <h2>.Net Core / C#</h2>
                    <div>
                        <Link to='/asp-net-core-cheat-sheet'>.Net Core Cheat Sheet</Link>
                    </div>
                    <div>
                        <Link to='/c-sharp-cheat-sheet'>C# cheat sheet</Link>
                    </div>
                </section>

                <section>
                    <title>CSS</title>
                    <div>
                        <Link to='/css-cheat-sheet'>CSS Cheat Sheet</Link>
                    </div>
                </section>

                <section>
                    <h2>Imgur</h2>
                    <div>
                        <Link to='/imgur-upload-and-embed-image'>Imgur: Upload and Embed Image</Link>
                    </div>
                </section>

                <section>
                    <h2>Postman</h2>
                    <div>
                        <Link to='/postman-cheat-sheet'>Postman Cheat Sheet</Link>
                    </div>
                    <div>
                        <Link to='/postman-variables'>Postman Variables</Link>
                    </div>
                </section>

                

                

                {/*
                <p>Welcome to your new single-page application, built with:</p>
                <ul>
                    <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
                    <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
                    <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
                </ul>
                <p>To help you get started, we have also set up:</p>
                <ul>
                    <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
                    <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
                    <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
                </ul>
                <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
                */}
            </div>
        );
    }
}
