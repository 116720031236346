import React, { Component } from 'react';

export class ReactCheatSheet extends Component {
    static displayName = ReactCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>React Cheat Sheet</h3>
                <section>
                    <h4>Map</h4>
                    <div>
                        <a href="https://learn.microsoft.com/en-us/training/modules/react-work-with-components-and-data/5-map">
                            Map basic syntax
                        </a>
                        <div>
                            <div>
                                Note: React has a guideline that all items displayed should have a key.
                            </div>
                            <div>
                                Second param to map is the index <br />
                                const squared = numbers.map((number, index) =&gt; ...
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}
