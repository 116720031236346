import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { CreateNewReactPage } from "./components/Articles/CreatingMarksCode/CreateNewReactPage";
import { GitNotRecognizedPage } from "./components/Articles/Git/GitNotRecognized";
import { GitCheatSheet } from "./components/Articles/Git/GitCheatSheeet";
import { ReactCheatSheet } from "./components/Articles/React/ReactCheatSheet";
import { DotNetReactBasics } from "./components/Articles/React/DotNetReactBasics"; 
import { ReactWarnings } from './components/Articles/React/ReactWarnings';
import { AzurePowershellSwitchSubscription } from './components/Articles/Azure/AzurePowershellSwitchSubscription';
import { PowershellCheatSheet } from './components/Articles/Powershell/PowershellCheatSheet';

import { ImgurUploadAndEmbedImage } from './components/Articles/CreatingMarksCode/ImgurUploadAndEmbedImage';
import { MSLearnAzureAppService } from './components/Articles/MSLearn/MSLearn_AzureAppService';
import { MSLearnBicep } from './components/Articles/MSLearn/MSLearn_Bicep';
import { MSLearnAzureActiveDirectory } from './components/Articles/MSLearn/MSLearnAzureActiveDirectory';
import { AzureSwitchDirectory } from './components/Articles/Azure/Azure_SwitchDirectory';
import { AzureServiceBusBasics } from './components/Articles/Azure/AzureServiceBusBasics';
import { AzureDocumentationLinks } from './components/Articles/Azure/AzureDocumentationLinks';
import { CreateNugetPackage } from './components/Articles/Nuget/CreateNugetPackage';

import { ReactAddLink } from './components/Articles/React/React_AddLink';

import { SqlCheatSheet } from "./components/Articles/SQL/SqlCheatSheet";
import { SetupLocalSqlServerExpress } from "./components/Articles/SQL/SetupLocalSqlServerExpress";

import { CSharpCheatSheet } from './components/Articles/CSharp/CSharpCheatSheet';
import { AspNetCoreCheatSheet } from './components/Articles/AspNetCore/AspNetCoreCheatSheet';
import { CssCheatSheet } from './components/Articles/Css/CssCheatSheet';

import { PostmanCheatSheet } from './components/Articles/Postman/PostmanCheatSheet';
import { PostmanVariables } from './components/Articles/Postman/PostmanVariables';

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/react-add-link',
        element: <ReactAddLink />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/create-new-react-page',
        element: <CreateNewReactPage />
    },
    {
        path: '/git-cheat-sheet',
        element: <GitCheatSheet />
    },
    {
        path: '/git-not-recognized',
        element: <GitNotRecognizedPage />
    },
    {
        path: '/react-cheat-sheet',
        element: <ReactCheatSheet />
    },
    {
        path: '/dot-net-react-basics',
        element: <DotNetReactBasics />
    },
    {
        path: '/react-warnings',
        element: <ReactWarnings />
    },
    {
        path: '/azure-powershell-switch-subscription',
        element: <AzurePowershellSwitchSubscription />
    },
    {
        path: '/powershell-cheat-sheet',
        element: <PowershellCheatSheet />
    },
    {
        path: '/imgur-upload-and-embed-image',
        element: <ImgurUploadAndEmbedImage />
    },
    {
        path: '/mslearn-azureappservice',
        element: <MSLearnAzureAppService />
    },
    {
        path: '/mslearn-bicep',
        element: <MSLearnBicep/>
    },
    {
        path: '/mslearn-azure-active-directory',
        element: <MSLearnAzureActiveDirectory />
    },
    {
        path: '/azure-switch-directory',
        element: <AzureSwitchDirectory />
    },
    {
        path: '/azure-service-bus',
        element: <AzureServiceBusBasics />
    }, 
    {
        path: '/azure-documentation-links',
        element: <AzureDocumentationLinks />
    },
    {
        path: '/create-nuget-package',
        element: <CreateNugetPackage />
    },  
    {
        path: '/powershell-cheat-sheet',
        element: <CreateNugetPackage />
    },
    {
        path: '/sql-cheat-sheet',
        element: <SqlCheatSheet />
    },
    {
        path: '/sql-setup-sql-express',
        element: <SetupLocalSqlServerExpress />
    },
    {
        path: '/asp-net-core-cheat-sheet',
        element: <AspNetCoreCheatSheet />
    },
    {
        path: '/c-sharp-cheat-sheet',
        element: <CSharpCheatSheet />
    },
    {
        path: '/css-cheat-sheet',
        element: <CssCheatSheet />
    },
    {
        path: '/postman-cheat-sheet',
        element: <PostmanCheatSheet />
    },
    {
        path: '/postman-variables',
        element: <PostmanVariables />
    },
];

export default AppRoutes;
