import React, { Component } from 'react';

export class AzureServiceBusBasics extends Component {
    static displayName = AzureServiceBusBasics.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Azure Service Bus Basics</h3>
                <article>
                    <h4>Use <a href="https://github.com/paolosalvatori/ServiceBusExplorer/releases">Service Bus Explorer</a> to examine / investigate SB messages / errored messaged</h4>
                    <div>Note: looks like this is <a href="https://azure.microsoft.com/en-us/updates/sesrvice-bus-explorer/">available in the Azure Portal</a> now</div>
                    <div>
                        1) Get Azure Service Bus Explorer
                    </div>
                    <div>2) go to portal, find Service Bus Namespace instance</div>
                    <div>3) Shared Access Policies tab on the left under settings</div>
                    <div>4) Click policy, hopefully shows panel (blade?) with connection string</div>
                    <div>5) In Azure Service Explorer, top left, File {"=>"} Connect {"=>"} top left, select Enter connection string in drop down</div>
                    <div>6) Enter con str, click Save (if you want to use later via File {"=>"} Saved connections), Click Ok</div>
                    <div>
                        7) Find Topic / subscription under Topics in menu on the left, click messages button
                    </div>
                    <div>
                        8) Receive Mode should be Peek (not Peek and Delete!), click ok
                    </div>
                    <div>
                        9) In messages window, click row to get info on message
                    </div>
                    <div>
                        10) double click opens "Repair and submit" window
                    </div>
                    <div>
                        11) here you can edit values, then click "Submit" to re-submit message to a topic, will prompt you for the Topic to re-submit message to
                    </div>
                </article>
            </div>
        );
    }
}