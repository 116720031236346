import React, { Component } from 'react';

export class ReactWarnings extends Component {
    static displayName = ReactWarnings.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h2>React Warnings and resolutions</h2>
                <div>
                    <h3><a href="https://markscodecom.wordpress.com/2023/07/11/react-warnings/">React Warnings article / page on blog</a></h3>
                    <div>
                        Includes:
                        <ul>
                            <li>AppRoutes.js: Cannot find file</li>
                        </ul>
                    </div>
                </div>
                <div>
                    If you don't handle these warnings as they come up then the output is going to quickly become incomprehensible
                </div>
                <section>
                    <h4><h3>Warning:</h3> Unexpected Unicode BOM (Byte Order Mark)</h4>
                    <div>
                        Resolution: <br />
                        Use VSCode to save the file with a different (plain old UTF8) encoding <br/>
                        <a href="https://stackoverflow.com/questions/2554445/how-to-fix-byte-order-mark-found-in-utf-8-file-validation-warning">
                            Resolutions for stackoverflow - how to save with different encoding / utf8
                        </a>
                    </div>
                    <div>
                        <img src="https://i.imgur.com/xxM92Vn.png" alt="show Unexpected Unicode BOM Warning" />
                        <div>
                            <div>Ctrl+T to open terminal</div>
                            <div>Start typing in "&gt; Change File Encoding" and click it</div>
                        </div>
                        <img src="https://i.imgur.com/Qi5PNbD.png" alt="VSCode Save with Encoding" />
                        <img src="https://i.imgur.com/Q8IrG6b.png" alt="Save as utf eight" />
                    </div>
                </section>
                <section>
                    <h3>Warning:</h3> 
                    <h4>Redundant alt attribute. Screen-readers already announce `img` tags as an image</h4>
                    <div>
                        Resolution: Figured this one out just by reading the warning...
                        <br /> I had the word "Image" in the alt value, scrapped it and the warning went away
                    </div>
                    <img src="https://i.imgur.com/zKp9Fao.png" alt="Redundant alt attribute React warning" />
                    <img src="https://i.imgur.com/UIHVDEI.png" alt="Redundant alt attribute React warning resolution" />                    
                </section>
                <section>
                    <b>Imported JSX component My_ClassName must be in PascalCase or SCREAMING_SNAKE_CASE</b>
                    <div>
                        Solution: can't have an underscore in the js / jsx class name
                    </div>
                </section>
                <section>
                    <div>
                        Pulled MarksCode repo to another machine, debug and:
                    </div>
                    <div>
                        fail: Microsoft.AspNetCore.SpaProxy.SpaProxyLaunchManager[0]
                        <br/>Couldn't start the SPA development server with command 'npm start'.
                    </div>
                    <div>
                        Resolution: ???
                    </div>
                </section>
                <section>
                    <div>
                        Warning: The href attribute requires a valid value to be accessible
                    </div>
                    <div>
                        Solution: Had href="#" in an anchor. Change to url
                    </div>
                </section>
            </div>
        );
    }
}
