import React, { Component } from 'react';

export class GitCheatSheet extends Component {
    static displayName = GitCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Git Cheat Sheet</h3>
                <article>
                    <title>Git Commit:</title>
                    <div>
                        Tried: git commit
                    </div>
                    <div>
                        Got error message. Added "-a" for "commit it all"
                    </div>
                    <div>
                        Got new command prompt with weird colors asking for commit comment / message, couldn't figure
                        out how to enter the message it was asking for.
                    </div>
                    <div>
                        <div>Winner:</div>
                        <b>git commit -a -m "my awesome commit message"</b>
                    </div>
                    <div>
                        <div>Successful output looks like:</div>
                        <div>
                            [main 7f044ec] my awesome commit message
                            1 file changed, 21 insertions(+)
                        </div>
                    </div>
                </article>
                <article>
                    <title>
                        Git Push:
                    </title>
                    <div>
                        <b>git push</b>
                    </div>
                </article>
                <article>
                    <title>Remove folder (.vs folder) from Git:</title>
                    <div>
                        Add this to gitignore:
                    </div>
                    <div>
                        # Visual Studio 2015/2017 cache/options directory
                        <br />.vs/
                    </div>
                    <div>
                        If .vs folder / files have already been added to the repo then open a command prompt and use this command to remove the folder / files:
                    </div>
                    <div>(Bonus: to switch drive from c:\ to d drive just: <b>d:</b>)</div>
                    <div>
                        git rm -r --cached ".vs/"
                    </div>
                    <div>
                        I was in a parent directory in the repo and got this:
                    </div>
                    <div>
                        fatal: pathspec '.vs/' did not match any files
                    </div>
                    <div>
                        I looked for some solutions that would remove any folders named ".vs" in the repo / subdirectories
                        but... just changed my directory and ran the: <b>git rm -r --cached ".vs/"</b> command above.
                    </div>
                </article>
                <article>
                    <h4>Merge in VSCode</h4>
                    <div>
                        <img src="https://i.imgur.com/EqwsZd1.png" />
                        <img src="https://i.imgur.com/Kzy6oLA.png" />
                        <img src="https://i.imgur.com/GU64E54.png" />
                    </div>
                </article>
            </div>
        );
    }
}