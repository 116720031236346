import React, { Component } from 'react';

export class ImgurUploadAndEmbedImage extends Component {
    static displayName = ImgurUploadAndEmbedImage.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <article>
                    <title>Uploading an image to Imgur and display it on your page with an &lt;img&gt; tag</title>
                    <div>
                        Note: after upload, hacky solution at the bottom of the page via right-click and Copy image link
                    </div>
                    <div>
                        I think I'm going to want to setup an account and get API access in the future...
                        but for now...
                    </div>
                    <div>
                        <div>
                            <a class="biglink" href="https://imgur.com/upload">Upload link</a>
                        </div>
                        <div>
                            Or go to the <a href="https://imgur.com">Imgur home page</a> and click the green button with the plus sign
                        </div>
                    </div>
                </article>
                <article>
                    <title>
                        Using the image on your page
                    </title>
                    <div>
                        <div class="nope-title">Fail 1</div>
                        <div>After the image uploaded, I tried the <b>Copy Link</b> button</div>
                        <img src="https://i.imgur.com/4oOiUVf.png" />
                        <div class="nope">Nope</div>
                        <div>
                            That gives the link to this imgur page, it's not a link to the image itself. When I used that URL as the src of an img tag -&gt; no good, the image shows as broken
                        </div>
                    </div>
                    <div>
                        <div class="nope-title">Fail 2</div> 
                        <div>
                            Ok... what about embedding?
                            I tried the <b>Embed post</b> link / button at the bottom right
                        </div>
                        <img src="https://i.imgur.com/9QF1DMJ.png" />
                        <div class="nope">Nope</div>
                        <div>
                             The embed text / code has a script tag in it, JSX / React doesn't play nice with injecting a script like that'
                        </div>
                    </div>
                    <div>
                        <div class="solution">This is the solution that worked:</div>
                        <div>
                            What about just copying a link to the image and trying to use that?
                        </div>
                        <div>
                            <img src="https://i.imgur.com/E9mgik2.png" />
                        </div>
                        <div>
                            And then I used that url as the src property in an &lt;img&gt; tag so that looks like:
                            <div>
                                <b>&lt;img src="https://i.imgur.com/E9mgik2.png" /&gt;</b>
                            </div>
                        </div>
                        <div>
                            So... if this website were to get much traffic (very low risk...) then this prob wouldn't work, Imgur would prob flag me (the image or the site) and
                            then quit serving the image on this domain / non-imgur domains. But for now...
                        </div>
                        <div>
                            P.S. You can also right-click the image in your browser and click "Inspect" and then copy
                            the img tag from dev tools:
                        </div>
                        <img src="https://i.imgur.com/QgDzrIs.png" />
                    </div>
                </article>
            </div>
        );
    }
}