import React, { Component } from 'react';

export class SqlCheatSheet extends Component {
    static displayName = SqlCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Sql Cheat Sheet</h3>
                <article>
                    <h4>Find tables by name:</h4>
                    <div>
                        SELECT * FROM sys.tables WHERE [name] LIKE '%something%'
                    </div>
                    <h4>FindTables PROC:</h4>
                    <div>
                        <br/>CREATE PROC FindTables
                        <br />@Name VARCHAR(MAX)
                        <br />AS
                        <br />SELECT * FROM sys.tables WHERE [name] LIKE '%' + @Name + '%'
                        <br />GO
                    </div>
                </article>
                <article>
                    <h4>FindProc proc:</h4>
                    <div>
                        CREATE PROC FindProc @Name VARCHAR(MAX)
                        <br />AS
                        <br />SELECT * FROM sys.objects WHERE [name] LIKE '%' + @Name + '%'
                        <br />GO
                    </div>
                    <h4>Search Stored Procedures by text in defintion:</h4>
                    <div>
                        DECLARE @SearchText VARCHAR(MAX) = 'something'
                        <br />
                        <br />SELECT DISTINCT o.[name] AS Object_Name, o.type_desc
                        <br />FROM sys.sql_modules m
                        <br />INNER JOIN sys.objects o ON m.object_id = o.object_id
                        <br />WHERE m.[definition] LIKE '%' + @SearchText + '%'
                        <br />ORDER BY o.[name]
                    </div>
                </article>
                <article>
                    <h4>sp_depends [stored-proc-name]</h4>
                    <div>
                        shows what stored proc depends on dude
                    </div>
                </article>
                <article>
                    <h4>PROC history of changes (dates...):</h4>
                    <div>
                        <br />SELECT SPECIFIC_NAME, CREATED, LAST_ALTERED, *
                        <br />FROM INFORMATION_SCHEMA.ROUTINES
                        <br />WHERE ROUTINE_TYPE = N'PROCEDURE'
                        <br />--AND ROUTINE_SCHEMA = N'dbo'
                        <br />ORDER BY INFORMATION_SCHEMA.ROUTINES.LAST_ALTERED
                    </div>
                </article>
            </div>
        );
    }
}
