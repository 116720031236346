import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class PostmanCheatSheet extends Component {
    static displayName = PostmanCheatSheet.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Postman Cheat Sheet</h3>

                <article>
                    <h4>Variables</h4>
                    <div>
                        <Link to="/postman-variables">Postman Environment Variables</Link>
                    </div>
                </article>
            </div>
        );
    }
}
