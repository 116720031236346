import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MSLearnAzureAppService extends Component {
    static displayName = MSLearnAzureAppService.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <a href="https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/">Explore Azure App Service</a>
                <div>
                    https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/2-azure-app-service
                    Powershell to list app service images
                    az webapp list-runtimes --os-type linux
                    az webapp list-runtimes --os-type windows
                </div>
                <div>
                    https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/3-azure-app-service-plans
                    App Service plan - your group of VMs (size, price, etc) in a region for running apps / funcs
                    You can use the plan to scale
                    Maybe improve performance by moving app to it's own app service plan
                </div>
                <div>
                    <a href="https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/4-deploy-code-to-app-service">ways to deploy</a>
                    some functionality (deployment slots, straging and prod) to eliminate downtime / warm up your deployed app
                </div>
                <div>
                    <a href="https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/5-authentication-authorization-app-service">Pitch to use built in auth</a>
                    Auth Module:
                    Authenticates users with the specified provider
                    Validates, stores, and refreshes tokens
                    Manages the authenticated session
                    Injects identity information into request headers
                </div>
                <div>
                    <a href="https://learn.microsoft.com/en-us/training/modules/introduction-to-azure-app-service/6-network-features">Networking stuff</a>
                    Don't understand any of this
                    What's this about? "If you want to see all the addresses that your app might use in a scale unit, there's a property called possibleOutboundIpAddresses that will list them."
                </div>
                <div>
                    <a href="https://learn.microsoft.com/en-us/training/modules/host-a-web-app-with-azure-app-service/5-exercise-implement-a-web-application?pivots=csharp">
                        Unit 5 of 8
                    </a>
                    <div>
                        I was having a little trouble testing this new app on this step.
                        <br /> Pretty sure I just goofed the port but...
                        <br /> Here's a link to Switch the Azure Directory to the MSLearn / sandbox directory:
                        <Link to='/azure-switch-directory'>Switch Azure Directory</Link>
                    </div>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
                <div>
                    <a href="">
                    </a>
                </div>
            </div>
        )
    }
}